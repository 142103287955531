import { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import { convertDateToISOString } from "../utils/generic";
import { GalleryView } from "../GalleryView/GalleryView";
import { SearchQuery, useSearch } from "../../Components/Context/SearchContext";
import {
  allBidSelectOptions,
  selectOptions,
} from "../../Pages/HomePage/constants";
import { CardView, TenderCardGroup } from "../TenderCard/TenderCardGroup";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { NoticeType } from "../../utils/EnumConstants";
import { useGetTenderList } from "../Api/ApiServices/useGetTenderList";
import { useAuth } from "../Context/AuthContext";
import { EBidPage } from "../EBids/EBids";
import { ENoticePage } from "../ENotice/ENotice";
import { onEbidClick, onENoticeClick } from "../../Pages/HomePage/utils";
import { useSearchParams } from "react-router-dom";
import { DateRange } from "rsuite/esm/DateRangePicker";

interface NoticeListingProps {
  defaultTabIndex: number;
  ebidCount: number;
  eNoticeCount: number;
}

export const NoticeListing = ({
  defaultTabIndex,
  ebidCount,
  eNoticeCount,
}: NoticeListingProps) => {
  const { isAuthenticate } = useAuth();
  const { query, page, setPage, setQuery } = useSearch();
  const [viewType, setViewType] = useState("list");
  const [tabIndex, setTabIndex] = useState(0);
  const [currentOptions, setCurrentOptions] = useState(selectOptions);
  const [showEbidsCount, setShowEBidsCount] = useState(true);
  const [showEnoticeCount, setShowENoticeCount] = useState(true);
  const [isPrivateOnly, setIsPrivate] = useState(false);
  const [noticeType, setNoticeType] = useState<NoticeType>(NoticeType.ALL);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const temp = localStorage.getItem("notification");
  const today = new Date();
  const currentDate = today.toLocaleDateString("en-CA");
  const [searchParams] = useSearchParams();

  const { data: noticeResponse, isLoading: bidsLoading } = useGetTenderList({
    page,
    query,
    isPrivateOnly: isPrivateOnly,
    notice_type: noticeType,
  });
  const bids = noticeResponse?.data?.data?.results;
  const totalBids = noticeResponse?.data?.data?.total ?? 0;

  const totalPageSize = noticeResponse?.data?.data?.page_size ?? 0;
  const QuerySet = (queryname: string, targetValue: string) => {
    let updatedValue = {
      [queryname]: targetValue,
    };
    setQuery((p: SearchQuery) => ({
      ...p,
      ...updatedValue,
    }));
  };

  const getView = (value: any) => {
    setViewType(value);
  };

  useEffect(() => {
    setTabIndex(defaultTabIndex);
  }, [defaultTabIndex]);

  useEffect(() => {
    const tabValue = searchParams.get("tab");
    if (tabValue) {
      // setTabIndex(Number(tabValue));
    }
  }, [searchParams]);

  useEffect(() => {
    if (tabIndex === 0 || tabIndex === 4) {
      setCurrentOptions(allBidSelectOptions);
      setViewType("list");
    } else {
      setCurrentOptions(selectOptions);
      setViewType("list");
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 0) {
      setIsPrivate(false);
      setNoticeType(NoticeType.ALL);
      // getTenders(page);
    } else if (tabIndex === 1) {
      setIsPrivate(true);
      setNoticeType(NoticeType.EBID);
      // getEbidTenders(page);
    } else if (tabIndex === 2) {
      setIsPrivate(true);
      setNoticeType(NoticeType.ENOTICE);
    } else if (tabIndex === 3) {
      setIsPrivate(false);
      setNoticeType(NoticeType.PPMO);
    } else {
      setIsPrivate(false);
      setNoticeType(NoticeType.NEWSPAPER);
    }
  }, [page, tabIndex, query, isAuthenticate]);

  useEffect(() => {
    const tempNotification = temp ? JSON?.parse(temp as string) : {};
    if (tempNotification?.eNoticeDate === currentDate || eNoticeCount <= 0) {
      setShowENoticeCount(false);
    } else {
      setShowENoticeCount(true);
    }

    if (tempNotification?.eBIdDate === currentDate || ebidCount <= 0) {
      setShowEBidsCount(false);
    } else {
      setShowEBidsCount(true);
    }
  }, [tabIndex, eNoticeCount, ebidCount]);
  const [startDate, endDate] = query.date.split("_");
  return (
    <div className="main-tab">
      <div className="switch-btns d-flex gap-2">
        <div className="input-block date-range-edit pr-2">
          <DateRangePicker
            className="date-range"
            format="yyyy-MM-dd"
            placeholder="Publish From - Publish To"
            value={startDate && endDate ? [new Date(startDate), new Date(endDate)] : null}
            onChange={(e) => {
              const date =
                e === null
                  ? ""
                  : convertDateToISOString(e[0]) +
                  "_" +
                  convertDateToISOString(e[1]);

              QuerySet("date", e === null ? "" : date);

              setDateRange(e);
            }}
          />
        </div>
        <GalleryView
          getView={getView}
          selectOptions={currentOptions}
          viewType={viewType as CardView}
        />
      </div>

      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
          setPage(1);
        }}
      >
        <TabList>
          <Tab>All Bids </Tab>
          <Tab>
            <div
              className="tab-head"
              onClick={() => {
                onEbidClick(ebidCount);
              }}
            >
              <div>
                <span style={{ color: "#b61e25" }}>E</span>-Bids
              </div>
              {showEbidsCount && <div className="count">{ebidCount}</div>}
            </div>
          </Tab>
          <Tab>
            <div
              className="tab-head"
              onClick={() => {
                onENoticeClick(eNoticeCount);
              }}
            >
              <div>
                <span style={{ color: "#b61e25" }}>E</span>-Notice
              </div>
              {showEnoticeCount && <div className="count">{eNoticeCount}</div>}
            </div>
          </Tab>
          <Tab>
            <div
              className="tab-head"
              onClick={() => {
                // onENoticeClick();
              }}
            >
              <div>PPMO</div>
              {/* {showEnoticeCount && (
              <div className="count">{eNoticeCount}</div>
            )} */}
            </div>
          </Tab>
          <Tab>
            <div
              className="tab-head"
              onClick={() => {
                // onENoticeClick();
              }}
            >
              <div>Newspaper</div>
              {/* {showEnoticeCount && (
              <div className="count">{eNoticeCount}</div>
            )} */}
            </div>
          </Tab>
        </TabList>
        <TabPanel>
          <TenderCardGroup
            tenderCard={bids}
            viewType={viewType as CardView}
            loading={bidsLoading}
            totalTender={totalBids}
            pageSize={totalPageSize}
          />
        </TabPanel>
        <TabPanel>
          <EBidPage
            viewType={viewType as CardView}
            tenderCard={bids}
            totalTender={totalBids}
            pageSize={totalPageSize}
            loading={bidsLoading}
          />
        </TabPanel>
        <TabPanel>
          <ENoticePage
            viewType={viewType as CardView}
            totalTender={totalBids}
            pageSize={totalPageSize}
            tenderCard={bids}
            loading={bidsLoading}
          />
        </TabPanel>
        <TabPanel>
          <ENoticePage
            viewType={viewType as CardView}
            totalTender={totalBids}
            pageSize={totalPageSize}
            tenderCard={bids}
            loading={bidsLoading}
          />
        </TabPanel>
        <TabPanel>
          <TenderCardGroup
            tenderCard={bids}
            viewType={viewType as CardView}
            loading={bidsLoading}
            totalTender={totalBids}
            pageSize={totalPageSize}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};
